.lending-overview_item {
    padding-bottom: 25px;
    page-break-inside: avoid;
}

.lending-overview_item_label {
    font-size: large;
}

.lending-overview_item_description {
    padding-left: 15px;
    white-space: pre;
}

.lending-overview_item_marking {
    margin-top: 10px;
}