.material_logo {
    display: none;
}

@media print {
    .material_logo {
        display: block;
        float: right;
        height: 130px;
    }
}