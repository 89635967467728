.lending-contract_header {
    height: 130px;
}

.lending-contract_signature-line {
    display: flex;
    flex-direction: row;
    column-gap: 80px;
    width: 100%;
}

.lending-contract_underline-top {
    display: inline-block;
    margin-top: 80px;
    border-top: 2px solid black;
    min-width: 150px;
}

.lending-contract_footer {
    font-size: small;
}

.lending-contract_footer > p {
    margin: 2px;
}