.regal-zettel ul {
    list-style: none;
}

.regal-zettel_item {
    padding-left: 20px;
    margin-bottom: 25px;
    margin-right: 40px;
    width: max-content;
    page-break-inside: avoid;
}

.regal-zettel_item_description {
    padding-left: 15px;
    white-space: pre;
}

.regal-zettel_item_marking {
    padding-left: 15px;
}

@media print {
    .regal-zettel button {
        display: none;
    }

    .regal-zettel_page {
        break-before: page;
    }

    .regal-zettel_footer {
        right: 0;
    }

    .regal-zettel_footer_arrow {
        margin-left: 60px;
        font-size: 90px;
    }

    .regal-zettel_location {
        font-size: 60px;
        margin-top: 0;
        margin-bottom: 20px;
    }

    .regal-zettel_item_label {
        font-size: 30px;
    }

    .regal-zettel_item_description {
        font-size: 20px;
    }

    .regal-zettel_item_marking {
        margin-top: 8px;
        font-size: 20px;
    }

}
