.App {
  text-align: center;
}

.blocksatz {
  text-align: justify;
}

@media screen {
  .print-footer {
    display: none;
  }
}

.print-footer {
  position: fixed;
  bottom: 0;
}

@page {
  size: A4;
  margin: 11mm 17mm 17mm 17mm;
}