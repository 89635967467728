.link-list {
    list-style: none;
    padding: 0;
    margin: 10px 10px 10px -10px;
    max-width: 400px;
}

.link-list_item {
    padding: 9px 40px 9px 0;
}

.link-list_item + .link-list_item {
    border-top: 2px solid;
    border-top-color: #f6a00f;
}

.link-list_item_link {
    padding-left: 10px;
    text-decoration: none;
    color: #004082;
}

.link-list_item_link:hover {
    text-decoration: underline;
}

@media (prefers-color-scheme: dark) {
    .link-list_item_link {
        color: #0090d2;
    }
}
